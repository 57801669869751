<template>
  <div class="swiper-banner">
    <div class="swiper-banner-wrapper">
      <!--   <swiper class="swiper" :options="swiperOption" ref="mySwiper">
        <swiper-slide
          v-for="(item, index) in list"
          class="banner-slide-item"
          :key="index"
          :style="{
            backgroundImage: `url(${item.pic})`,
            backgroundSize: '100% 100%',
          }"
        >
          <slot>
            <div class="swiper-desc-box">
              <div class="swiper-desc-title">专业 共赢</div>
              <div class="swiper-desc-content">
                我们为集成企业提供更专业的金融服务我们为集我们为集<br />
                我们为集成企业提供更专业的金融服务
              </div>
              <div class="swiper-desc-join-btn" @click="goLink(item)">
                立即加入
              </div>
            </div>
          </slot>
        </swiper-slide>
        <div class="swiper-pagination" slot="pagination"></div>
      </swiper> -->
      <el-carousel
        :interval="3000"
        arrow="hover"
        height="380px"
        @click.native="goLink"
        ref="mySwiper"
      >
        <el-carousel-item
          class="banner-slide-item"
          v-for="(item, index) in list"
          :key="index"
          :style="{
            backgroundImage: `url(${item.pic})`,
            backgroundSize: '100% 100%',
          }"
        >
          <slot>
            <div class="swiper-desc-box">
              <!-- <div class="swiper-desc-title">专业 共赢</div>
              <div class="swiper-desc-content">
                我们为集成企业提供更专业的金融服务我们为集我们为集<br />
                我们为集成企业提供更专业的金融服务
              </div> -->
              <!-- <div class="swiper-desc-join-btn" @click="goLink(item)">
                立即加入
              </div> -->
            </div>
          </slot>
        </el-carousel-item>
      </el-carousel>
    </div>
  </div>
</template>
<script>
import Swiper from "swiper";
import "swiper/swiper-bundle.css";

export default {
  props: {
    list: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      swiperOption: {
        notNextTick: false,
        autoplay: true,
        setWrapperSize: true,
        autoHeight: true,
        pagination: ".swiper-pagination",
        paginationClickable: true,
        mousewheelControl: true,
        observeParents: true,
        debugger: true,
        loop: false,
      },

      mySwiper: null,
    };
  },
  mounted() {
    this.$nextTick(() => {
      this.initSwiper();
    });
  },
  methods: {
    initSwiper() {
      setTimeout(() => {
        this.mySwiper = new Swiper(".swiper-container", {
          loopAdditionalSlides: 3,
          loop: true,
          speed: 2000,
          autoplay: {
            // 自动滑动
            delay: 1000, //1秒切换一次
            disableOnInteraction: false,
          },
          pagination: {
            // 如果需要分页器
            el: ".swiper-pagination",
            clickable: true,
          },
          observer: true, // 启动动态检查器(OB/观众/观看者)
          observeParents: true, // 修改swiper的父元素时，自动初始化swiper
        });
      }, 300);
    },
    goLink() {
      let activeIndex = this.$refs.mySwiper.activeIndex;
      // console.log(activeIndex)
      window.open(this.list[activeIndex].link);
    },
  },
};
</script>
<style lang="scss">
.el-carousel__indicators--horizontal {
  left: 75%;
}
.el-carousel__button {
  width: 10px;
  height: 10px;
  border-radius: 50%;
}
.el-carousel__indicator.is-active button {
  background-color: #004375;
}
</style>
<style lang="scss" scoped>
.swiper-banner {
  height: 380px;
  background: #d3d3d3;
  position: relative;
  .swiper-banner-wrapper {
    height: 100%;

    .banner-slide-item {
      // height: 380px;
      height: 100%;
      display: flex;
      justify-content: center;
      .swiper-desc-box {
        width: $safety-width;
        // margin: 0 auto;

        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        // align-items: center;
        .swiper-desc-title {
          color: #ffffff;
          line-height: 70px;
          text-shadow: 0px 2px 7px #162236;
          font-size: 50px;
        }
        .swiper-desc-content {
          margin-top: 22px;
          font-size: 18px;
          color: #ffffff;
          line-height: 25px;
          text-shadow: 0px 2px 7px #121f32;
        }
        .swiper-desc-join-btn {
          width: 156px;
          height: 50px;
          background: #004375;
          margin-top: 36px;

          color: #ffffff;
          line-height: 30px;
          letter-spacing: 1px;
          text-shadow: 0px 2px 7px rgba(18, 31, 50, 0.36);
          font-size: 22px;
          display: flex;
          justify-content: center;
          align-items: center;
          cursor: pointer;
          &:active {
            background-color: #023358;
          }
        }
      }
    }
  }
}
</style>